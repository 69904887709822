/*
* Form Error
*/
function checkMessageForm() {
 if(document.querySelector('.wpcf7-response-output')) {
  document.querySelector('.wpcf7-response-output').addEventListener('click', function(e) {
   e.preventDefault(); 
   document.querySelector('.wpcf7-response-output').classList.add('hide');
  })
 }
 
 if(document.querySelector('.wpcf7-submit')) {
  document.querySelector('.wpcf7-submit').addEventListener('click', function(e) {
   document.querySelector('.wpcf7-response-output').classList.remove('hide');
  })
 } 
}
checkMessageForm()