/*
* Scroll to tabs
*/
function scrollToTabs() {
 let buttonScroll = document.querySelector('.product-features A');
 if (!buttonScroll) {
  return
 }
 let elementScroll;
 buttonScroll.addEventListener('click', function (e) {
  e.preventDefault();
  elementScroll = buttonScroll.getAttribute('href');
  let scrollPosition = document.querySelector(`${elementScroll}`).getBoundingClientRect().top - document.querySelector('.menu-bar').getBoundingClientRect().height;
  window.scrollTo({
   top: scrollPosition,
   behavior: 'smooth'
  })
 })
}
scrollToTabs()