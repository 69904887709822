/*
* Burger Menu 
*/
function menuBurger() {
 let menuBar = document.querySelector('.menu-bar');
 if (!menuBar) {
  return
 }
 let burgerButton = menuBar.querySelector('.navbar-toggle');
 let menuIcon = menuBar.querySelector('.menu-menu-glowne-container');
 let menuIconHeight = menuBar.querySelector('.menu').getBoundingClientRect().height;
 let links = menuBar.querySelectorAll('.menu LI');
 let linksToScroll = menuBar.querySelectorAll('.menu .logo_item a, .menu .menu-item-58 a, .menu .menu-item-18 a');
 let changeHrefLinks = menuBar.querySelectorAll('#menu-item-18 a, .logo_item a, .logo_mobile a');
 let offsetStep;
 let href;
 let mobileViewPort = window.matchMedia('(max-width: 950px)');
 burgerButton.addEventListener('click', function (e) {
  e.preventDefault();
  offsetStep = menuBar.getBoundingClientRect().top;
  burgerButton.classList.toggle('active');
  menuIcon.classList.toggle('active');

  if (offsetStep > 1) {
   window.scrollBy({
    top: offsetStep,
    behavior: 'smooth'
   })
  }

  if (menuIcon.classList.contains('active')) {
   menuIcon.style.height = `${menuIconHeight}px`;
  } else {
   menuIcon.style.height = `0px`;
  }
 })

 if (mobileViewPort.matches) {
  links.forEach(element => {
   element.addEventListener('click', function () {
    burgerButton.classList.remove('active');
    menuIcon.classList.remove('active');
    menuIcon.style.height = `0px`;
   })
  })
 }
 
 //Check This :)
 if (!document.body.classList.contains('home')) {
  changeHrefLinks.forEach(element => {
   let pageHref = element.getAttribute('href');
   let pagePath = window.location.origin + '/' + pageHref;
   element.setAttribute('href', `${pagePath}`);
  })
 }

 linksToScroll.forEach(link => {
  link.addEventListener('click', function (e) {
   if (document.body.classList.contains('home')) {
    e.preventDefault();
   }
   if(link.parentElement.id == 'menu-item-58') {
    e.preventDefault();
   }
   href = link.getAttribute('href');
   offsetStep = document.querySelector(`${href}`).getBoundingClientRect().top - menuBar.getBoundingClientRect().height;
   window.scrollBy({
    top: offsetStep,
    behavior: 'smooth'
   })
  })
 })

 let headerHeight = document.querySelector('.header').getBoundingClientRect().height;

 window.addEventListener('scroll', function (e) {
  e.preventDefault();
  offsetStep = this.scrollY;
  if (headerHeight <= 1) {
   if (offsetStep > menuBar.getBoundingClientRect().height) {
    menuBar.classList.add('sticky');
   } else {
    menuBar.classList.remove('sticky');
   }
  } else {
   if (offsetStep > (headerHeight + menuBar.getBoundingClientRect().height)) {
    menuBar.classList.add('sticky');
   } else {
    menuBar.classList.remove('sticky');
   }
  }
 })

 let headerButton = document.querySelector('.starting-link');
 if (!headerButton) {
  return
 }

 headerButton.addEventListener('click', function (e) {
  e.preventDefault();
  href = headerButton.getAttribute('href');
  offsetStep = document.querySelector(`${href}`).getBoundingClientRect().top - menuBar.getBoundingClientRect().height;
  window.scrollBy({
   top: offsetStep,
   behavior: 'smooth'
  })
 })
}
menuBurger()
