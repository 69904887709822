/*
* Menu Dla Produktów
*/
function productTabs() {
 let categoryList = document.querySelector('.category-list');
 if (!categoryList) {
  return
 }
 let categoryListWithChild = categoryList.querySelectorAll('LI.has-children');
 let categorySubMenu = categoryList.querySelectorAll('.child-category-list');
 let categorySubMenuHeight;
 for (let i = 0; i < categoryListWithChild.length; i++) {
  categoryListWithChild[i].addEventListener('click', function () {
   let categorySubMenuHeight = categorySubMenu[i].scrollHeight;
   if (categoryListWithChild[i].classList.contains('open')) {
    categorySubMenu[i].style.height = `0px`;
    setTimeout(function () {
     categoryListWithChild[i].classList.remove('open');
    }, 300)
   } else {
    categoryListWithChild[i].classList.add('open');
    categorySubMenu[i].style.height = `${categorySubMenuHeight}px`;
   }
  })
 }

 for (let i = 0; i < categoryListWithChild.length; i++) {
  if (categoryListWithChild[i].classList.contains('open')) {
   categoryListWithChild[i].classList.add('open');
   categorySubMenu[i].style.height = `${categorySubMenuHeight}px`;
   setTimeout(function () {
    categorySubMenu[i].style.height = `auto`;
   })
  }
 }
}
setTimeout(productTabs, 200)