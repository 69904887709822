/*
* Tab Slider
*/
function sliderTabs() {
 let sliderContainer = document.querySelectorAll('.scrollbar-slider');
 if (!sliderContainer.length) {
  return
 }
 for (let i = 0; i < sliderContainer.length; i++) {
  let sliderList = sliderContainer[i].querySelector('.items-list');
  let sliderItems = sliderList.querySelectorAll('.items-list LI');
  let next = sliderContainer[i].querySelector('.next');
  let prev = sliderContainer[i].querySelector('.prev');
  let slideWidth = sliderList.querySelector('.items-list LI').getBoundingClientRect().width + 14;
  let slide = 0;
  let firtsMedia = window.matchMedia('(min-width: 501px) and (max-width: 900px)');
  let secondMedia = window.matchMedia('(max-width: 500px)');

  if (sliderItems.length < 3) {
   next.style.display = 'none';
   prev.style.display = 'none';
  }

  function counterSlide() {
   if (firtsMedia.matches) {
    return 2;
   } else if (secondMedia.matches) {
    return 1;
   } else {
    return 3;
   }
  }

  setInterval(() => {
   counterSlide()
  }, 3000);

  function slideMove() {
   sliderList.style.transform = `translateX(${-slide * slideWidth}px)`;
  }

  next.addEventListener('click', function (e) {
   e.preventDefault();
   slide++;

   if (slide > sliderItems.length - counterSlide()) {
    slide = 0;
   }

   slideMove()
  })

  prev.addEventListener('click', function (e) {
   e.preventDefault();
   slide--;

   if (slide < 0) {
    slide = sliderItems.length - counterSlide();
   }

   slideMove()
  })
 }
}
sliderTabs()